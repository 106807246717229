<template>
  <div>
    <b-row>
      <b-col>
        <b-card
          @click="workOrderItemClick"
          :class="`work-order-item ${getColor}-border`"
        >
          <b-card-title class="mb-1" style="font-size: 16px">
            <span class="pr-1 pb-1">
              <feather-icon
                size="18"
                icon="BriefcaseIcon"
              ></feather-icon> </span
            >{{ itemFields.name }}</b-card-title
          >
          <b-row>
            <b-col class="d-flex align-items-center">
              <span class="mr-50 pb-25">
                <feather-icon
                  size="12"
                  icon="CircleIcon"
                  :class="`${getColor}-color `"
                >
                </feather-icon>
              </span>
              {{ getPriorityName }} Priority</b-col
            >
          </b-row>
          <b-row class="mt-50"
            ><b-col>{{ itemFields.assignee }}</b-col></b-row
          >
          <b-row class="mt-50"
            ><b-col class="d-flex justify-content-end"
              >{{
                formatDate(itemFields.startDate, {
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              }}
              -
              {{
                formatDate(itemFields.endDate, {
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              }}</b-col
            ></b-row
          >
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed, toRefs } from "@vue/composition-api";
import { BRow, BCol, BCard, BCardTitle, BCardBody } from "bootstrap-vue";
import store from "@/store";
import { formatDate } from "@core/utils/filter";
import { size } from "vee-validate/dist/rules";
import { months } from "moment";
export default {
  components: { BRow, BCol, BCard, BCardTitle, BCardBody },
  props: {
    itemFields: {
      default() {
        return {
          id: 0,
          name: "",
          assignee: "",
          startDate: new Date(),
          endDate: new Date(),
          priority: 1,
          status: 1,
        };
      },
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { itemFields } = toRefs(props);
    const workOrderItemClick = () => {
      emit("ItemClick");
    };
    const getColor = computed(() => {
      let colorName = "";
      if (itemFields.value) {
        switch (itemFields.value.priority) {
          case 1: //High
            colorName = "red";
            break;
          case 2: //Medium
            colorName = "yellow";
            break;
          case 3: //Low
            colorName = "green";
            break;
          default:
            colorName = "yellow";
        }
      }

      return colorName;
    });
    const getPriorityName = computed(() => {
      const workorderFields =
        store.getters["pamis-work-order-module/GetWorkOrderFields"];
      const priorities = workorderFields.priorities;
      const selectedPriority = priorities.find(
        (e) => e.id == itemFields.value.priority
      );
      return selectedPriority.name;
    });
    return {
      formatDate,
      getPriorityName,
      getColor,
      workOrderItemClick,
    };
  },
};
</script>

<style scoped>
.work-order-item {
  background: rgb(255, 255, 255);
}
.work-order-item :hover {
  cursor: pointer;
}
.green-border {
  box-shadow: #0ac282 -5px 5px !important;
}
.orange-border {
  box-shadow: #fe9365 -5px 5px !important;
}

.yellow-border {
  box-shadow: #edd000 -5px 5px !important;
}
.red-border {
  box-shadow: #f31111 -5px 5px !important;
}
.green-color {
  stroke: #0ac282;
}
.orange-color {
  stroke: #fe9365;
}

.yellow-color {
  stroke: #edd000;
}
.red-color {
  stroke: #f31111;
}
</style>