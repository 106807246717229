<template>
  <b-sidebar
    id="add-edit-workorder-sidebar"
    :visible="isAddEditWorkorderSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="
      () => {
        resetWorkOrderFields();
      }
    "
    @change="(val) => $emit('update:is-add-edit-workorder-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{
            actionType == 1
              ? $i18n.t("Add New Work Order")
              : actionType == 2
              ? $i18n.t("Update Work Order")
              : $i18n.t("Delete Work Order")
          }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <b-form-group :label="$i18n.t('Status')" v-if="actionType != 1">
            <v-select
              id="prioriyId"
              v-model="workOrderData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="statusOptions"
              :reduce="(options) => options.id"
              class="select-size-lg"
            >
              <template #option="{ id, name }">
                <span> {{ name }}</span
                ><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Priority')">
            <v-select
              id="priorityId"
              v-model="workOrderData.priority"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(options) => options.id"
              :options="priorityOptions"
              class="select-size-lg"
            >
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Assignee')">
            <b-form-input
              id="workOrdername"
              v-model="workOrderData.assignee"
              autofocus
              trim
              :placeholder="$i18n.t('name')"
            />
          </b-form-group>
          <b-form-group :label="$i18n.t('Name')">
            <b-form-input
              id="workOrdername"
              v-model="workOrderData.name"
              autofocus
              trim
              :placeholder="$i18n.t('name')"
            />
          </b-form-group>
          <validation-provider #default="validationContext" name="Start Date">
            <!-- rules="required" -->
            <b-form-group :label="$i18n.t('StartDate')">
              <flat-pickr
                id="startDate"
                v-model="workOrderData.startDate"
                class="form-control"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                :placeholder="$i18n.t('StartDate')"
              />
              <b-form-invalid-feedback
                style="color: red"
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}*
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="End Date">
            <!-- rules="required" -->
            <b-form-group :label="$i18n.t('EndDate')">
              <flat-pickr
                id="endDate"
                v-model="workOrderData.endDate"
                class="form-control"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                :placeholder="$i18n.t('EndDate')"
              />
              <b-form-invalid-feedback
                style="color: red"
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}*
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ actionType == 1 ? $i18n.t("Add") : $i18n.t("Update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="resetWorkOrderFields"
            >
              {{ $i18n.t("Cancel") }}
            </b-button>
            <b-button
              v-if="actionType == 2"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mx-2"
              variant="danger"
              @click="deleteWorkOrder"
            >
              {{ $t("Delete") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ref, computed, toRefs, watch } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
//import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import { GetLocalDate } from "@/utils/utils";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddEditWorkorderSidebarActive",
    event: "update:is-add-edit-workorder-sidebar-active",
  },
  props: {
    isAddEditWorkorderSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
    selectedWorkOrder: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      required,
    };
  },
  methods: {
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Process Successful",
          icon: "BellIcon",
        },
      });
    },
  },

  setup(props, { emit }) {
    const { actionType, selectedWorkOrder, isAddEditWorkorderSidebarActive } =
      toRefs(props);
    const statusOptions = ref([
      { id: 1, name: "To Do" },
      { id: 2, name: "Ongoing" },
      { id: 3, name: "Done" },
      { id: 4, name: "On Hold" },
    ]);

    const priorityOptions = ref([
      { id: 1, name: "High" },
      { id: 2, name: "Medium" },
      { id: 3, name: "Low" },
    ]);

    const assigneeOptions = ref([
      { id: 1, name: "Ege" },
      { id: 2, name: "Ruken" },
      { id: 3, name: "Özgü" },
      { id: 4, name: "Can" },
    ]);
    const blankWorkOrderData = {
      id: null,
      status: null,
      priority: null,
      name: null,
      assignee: null,
      startDate: null,
      endDate: null,
    };

    const workOrderData = ref(JSON.parse(JSON.stringify(blankWorkOrderData)));

    const workOrderFields = computed(() => {
      return store.getters["pamis-work-order-module/GetWorkOrderFields"];
    });

    watch(isAddEditWorkorderSidebarActive, (newVal) => {
      if (actionType.value == 2) {
        workOrderData.value = JSON.parse(
          JSON.stringify(selectedWorkOrder.value)
        );
      }
    });

    const resetWorkOrderFields = () => {
      workOrderData.value = blankWorkOrderData;
      emit("update:is-add-edit-workorder-sidebar-active", false);
    };

    watch(workOrderFields, (newVal) => {
      if (newVal.statuses || newVal.priorities) {
        statusOptions.value = newVal.statuses;
        priorityOptions.value = newVal.priorities;
      }
    });

    const submitForm = () => {
      const data = workOrderData.value;

      const formData = {
        id: data.id,
        name: data.name,
        assignee: data.assignee,
        startDate: GetLocalDate(data.startDate),
        endDate: GetLocalDate(data.endDate),
        priority: data.priority,
        status: data.status,
      };
      let actionName = "";

      if (actionType.value == 1) {
        formData.startDate = new Date(); //on create task
        formData.status = 1;
        // Add New
        actionName = "AddNewWorkOrder";
      } else if (actionType.value == 2) {
        actionName = "EditWorkOrder";
        if (data.status == 3) {
          // if its Done
          formData.endDate = new Date();
        }
      }
      store
        .dispatch(`pamis-work-order-module/${actionName}`, formData)
        .then(() => {
          resetWorkOrderFields();
          emit("refetch-data");
        });
    };

    const deleteWorkOrder = () => {
      store
        .dispatch(
          "pamis-work-order-module/DeleteWorkOrder",
          workOrderData.value.id
        )
        .then(() => {
          resetWorkOrderFields();
          emit("refetch-data");
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetWorkOrderFields);

    return {
      //  onSubmit,
      resetWorkOrderFields,
      blankWorkOrderData,
      submitForm,
      workOrderData,
      priorityOptions,
      statusOptions,
      refFormObserver,
      deleteWorkOrder,
      assigneeOptions,
      getValidationState,
      resetForm,
    };
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-edit-work-order-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
